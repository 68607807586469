import React from "react";
import { Navigate } from "react-router-dom";

// Mock function to check admin role (replace with real logic)
const isAdmin = () => {
  const data = JSON.parse(localStorage.getItem("authUser")); // Example
  return data && data.user.role === "Admin";
  // return user;
};

export const AdminProtected = ({ children }) => {
  if (!isAdmin()) {
    return <Navigate to={{ pathname: "/auth-login"}} />;
  }
  return children;
};
