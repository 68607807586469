import React from "react";
import Sidebar from "./Sidebar"; // Your admin sidebar component
import "./AdminLayout.css";

const Index = ({ children }) => (
  <div className="admin-layout">
    <Sidebar />
    <div className="admin-content">{children}</div>
  </div>
);

export default Index;
