import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";

const Index = () => {
  const [users, setUsers] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedCategoryId, setUpdatedCategoryId] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newCategoryId, setNewCategoryId] = useState("");

  // Fetch users and user categories on component mount
  useEffect(() => {
    fetchUsers();
    fetchUserCategories();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/users"); // Replace with your API endpoint
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserCategories = async () => {
    try {
      const response = await axios.get("/user-categories"); // Replace with your API endpoint
      setUserCategories(response.data);
    } catch (error) {
      console.error("Error fetching user categories:", error);
    }
  };

  const openEditModal = (user) => {
    setEditingUser(user._id);
    setUpdatedName(user.name);
    setUpdatedEmail(user.email);
    setUpdatedPassword("");
    setUpdatedCategoryId(user.userCategoryId || "");
    setIsEditModalOpen(true);
  };

  const openCreateModal = () => {
    setNewName("");
    setNewEmail("");
    setNewPassword("");
    setNewCategoryId("");
    setIsCreateModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditingUser(null);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleSave = async () => {
    try {
      await axios.put(`/update-user/${editingUser}`, {
        name: updatedName,
        email: updatedEmail,
        password: updatedPassword,
        userCategoryId: updatedCategoryId,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeEditModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleCreate = async () => {
    try {
      await axios.post("/register", {
        username: newName,
        email: newEmail,
        password: newPassword,
        category: newCategoryId,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeCreateModal();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <div className="manage-users">
      <h1>Manage Users</h1>
      <button onClick={openCreateModal} className="create-user-button">
        Create User
      </button>
      <table className="user-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Department</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {users.map((user) => (
          <tr key={user._id}>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
              {userCategories.find((cat) => cat._id === user.userCategoryId)?.name || "---"}
            </td>
            <td>
              <button onClick={() => openEditModal(user)}>Edit</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>

      {/* Modal for Editing User */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        className="edit-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Edit User</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={updatedName}
              onChange={(e) => setUpdatedName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={updatedEmail}
              onChange={(e) => setUpdatedEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="text"
              value={updatedPassword}
              onChange={(e) => setUpdatedPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Department:</label>
            <select
              value={updatedCategoryId}
              onChange={(e) => setUpdatedCategoryId(e.target.value)}
            >
              <option value="">Select Category</option>
              {userCategories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-actions">
            <button type="button" className="modal-actions-save" onClick={handleSave}>
              Save
            </button>
            <button type="button" onClick={closeEditModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for Creating User */}
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={closeCreateModal}
        className="create-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Create User</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Department:</label>
            <select
              value={newCategoryId}
              onChange={(e) => setNewCategoryId(e.target.value)}
            >
              <option value="">Select Category</option>
              {userCategories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-actions">
            <button type="button" className="modal-actions-create" onClick={handleCreate}>
              Create
            </button>
            <button type="button" onClick={closeCreateModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Index;
