import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getProfileDetails = () => {
  // return api.get(url.GET_PROFILE_DETAILS);
  const profileDetails = localStorage.getItem("authUser");
  const data = profileDetails ? JSON.parse(profileDetails) : null;
  return {
    basicDetails: {
      name: data.user.name,
      email: data.user.email,
      phone: data.user.phone,
    },
  };
};

const getSettings = () => {
  return api.get(url.GET_USER_SETTINGS);
};
const updateSettings = (field: string, value: any) => {
  return api.update(url.UPDATE_ETTINGS, {
    field: field,
    value: value,
  });
};

export { getProfileDetails, getSettings, updateSettings };
