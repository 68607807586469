import React from "react";

interface IndexProps {}
const Index = (props: IndexProps) => {

  return (
    <>
      <div>
        <h1>Admin Dashboard</h1>
        <p>Welcome to the admin dashboard.</p>
      </div>
    </>
  );
};

export default Index;
