import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal"; // Install using npm install react-modal

const ViewLogs = () => {
  const [logs, setLogs] = useState([]);
  // Fetch users and user categories on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/logs"); // Replace with your API endpoint
      setLogs(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div className="manage-users">
      <h1>Manage Users</h1>
      <table className="user-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {logs.map((log) => (
          <tr key={log._id}>
            <td>{log.user.name}</td>
            <td>{log.action}</td>
          </tr>
        ))}
        </tbody>
      </table>

    </div>
  );
};

export default ViewLogs;
