/*
pages
*/
//root
import Root from "../pages/Root/index";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RecoverPassword from "../pages/Authentication/RecoverPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import LockScreen from "../pages/Authentication/LockScreen";

// dashboard
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin/index";
import ManageUsers from "../pages/Admin/ManageUsers";
import ViewLogs from "../pages/Admin/ViewLogs";
import StarterPage from "../pages/StarterPage/index";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Login /> },
  // { path: "/auth-register", component: <Register /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-changepassword", component: <ChangePassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/logout", component: <Logout /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", component: <Root /> },
  {
    path: "/admin/users",
    component: <ManageUsers />,
  },
];
const adminRoutes: Array<RouteProps> = [
  {
    path: "/admin",
    component: <Admin />,
  },
  {
    path: "/admin/logs",
    component: <ViewLogs />,
  },
];

export { publicRoutes, privateRoutes, adminRoutes };
