import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Admin Panel</h2>
      <ul>
        <li>
          <NavLink to="/admin" activeClassName="active-link">
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/users" activeClassName="active-link">
            Manage Users
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/logs" activeClassName="active-link">
            View Logs
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
